import { BlocksContent, Button, Image } from "@global";
import { SanityInvestorResource } from "@graphql-types";
import { Container } from "@util/standard";
import React from "react";

interface Props {
  data: SanityInvestorResource;
}

const ResourcePreview = ({ data }: Props) => {
  const { image, description, resource } = data;

  const handleClick = () => {
    if (resource && resource?.asset && resource?.asset?.url) {
      const url = `${resource.asset.url}?dl`;
      window.open(url, "_blank");
    }
  };

  return (
    <Container width="100%" flexDirection="column">
      {image && <Image width="100%" height="100%" data={image} objectFit="cover" />}
      {description && <BlocksContent data={description} />}
      {resource && (
        <div>
          <Button margin="20px 0 0 0 " theme="base" onClick={handleClick}>
            Download Resource
          </Button>
        </div>
      )}
    </Container>
  );
};

export default ResourcePreview;
