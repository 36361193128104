import { Button } from "@global";
import { SanityInvestorResourceGroup } from "@graphql-types";
import { Container, GridContainer, PageWidth, Section } from "@util/standard";
import React, { useState } from "react";
import ResourcePreview from "./resourcePreview";

interface Props {
  data: SanityInvestorResourceGroup;
}

const ResourceGroup = ({ data }: Props) => {
  const { title, resources } = data;

  const [shownNumbers, setShownNumbers] = useState(3);

  return (
    <Section>
      <PageWidth>
        <>
          <h3>{title}</h3>
          <GridContainer
            repeat={3}
            tabletRepeat={1}
            rowGap={20}
            columnGap={20}
            margin="50px 0 0 0 "
          >
            {resources &&
              resources?.map(resource => {
                if (resource == null) return null;
                return <ResourcePreview data={resource} />;
              })}
          </GridContainer>
          {/* {resources && resources.length > shownNumbers && (
            <Container margin="50px auto">
              <Button
                theme="base"
                onClick={() => {
                  setShownNumbers(pre => pre + 3);
                }}
              >
                Load More
              </Button>
            </Container>
          )} */}
        </>
      </PageWidth>
    </Section>
  );
};

export default ResourceGroup;
