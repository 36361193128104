import React, { useRef } from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";

import InfoSlider from "@components/infoSlider";
import ResourceGroup from "@components/resourceGroup";
import { BlocksContent } from "@global";
import { Query } from "@graphql-types";
import { BasicHero, Header, SEO } from "@shared";
import { PageWidth } from "@util/standard";
import { colors } from "@util/constants";
import { usePageMeta } from "@util/logicHooks";

interface Props extends PageProps {
  data: Query;
}

const InvestorCenter = ({ data }: Props) => {
  if (data == null) return null;
  const { sanityHeader, sanityInvestorCenter } = data;
  if (sanityInvestorCenter == null) return null;
  const { seo, title, team, blockContent, investorResources, pageColour, _type } =
    sanityInvestorCenter;

  usePageMeta(pageColour?.colour?.hex, _type);
  const dynamicsFormRef = useRef(null);

  return (
    <Wrapper>
      <SEO seoData={seo} slug="investor-center" />
      <Header data={sanityHeader} />
      <BasicHero heading={title} />

      <PageWidth margin="75px auto">
        <BlocksContent data={blockContent} />
      </PageWidth>

      {team &&
        team.map((data, index) => {
          if (data == null || data.title == null) return null;
          const isCream = index % 2 === 0;
          return (
            <StyledInfoWrapper isCream={isCream}>
              <InfoSlider key={data.title + index} data={data} overrideBackgroundColour={isCream} />
            </StyledInfoWrapper>
          );
        })}
      {investorResources &&
        investorResources.map(resourceGroup => {
          if (resourceGroup == null) return;
          return <ResourceGroup data={resourceGroup} />;
        })}
    </Wrapper>
  );
};

export default InvestorCenter;

const Wrapper = styled.div`
  margin-bottom: 80px;
`;

export const query = graphql`
  query investorCenterQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityInvestorCenter {
      title
      pageColour {
        ...sanityColorPicker
      }
      blockContent {
        _rawBlockContent(resolveReferences: { maxDepth: 4 })
      }
      blocks {
        ...staticPageBlocks
      }
      team {
        ...sanityInfoSlider
      }
      seo {
        ...sanitySeo
      }
      investorResources {
        ...sanityInvestorResourceGroup
      }
    }
  }
`;

const StyledInfoWrapper = styled.div<{ isCream?: boolean }>`
  background-color: ${props => (props.isCream ? colors.cream : colors.white)};
  padding: 75px 0px 37.5px 0px;
  margin-bottom: 75px;

  img {
    object-fit: cover !important;
  }
`;
